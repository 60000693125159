//Libs
import React from "react";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectCurrentUser } from "Redux/User/user-selectors";
import { setCurrentUser } from "Redux/User/user-actions";
//Actions
import { setCompanyData } from "Redux/DynamicConfigCompany/dcc-action";
import { setModuleOrganization } from "Redux/DynamicConfigCompany/dcc-action";
import { setOrganizationSeleted } from "Redux/Organization/organizarion-action";
//Services
import { signUp } from "Services/UserService";
import MixPanel from "Services/MixPanelService";
import { getInterests, getProfessions } from "Services/CompanyService";
//Config
import { ROUTES } from "Config/config";
//Selectors
import {
  selectCompany,
  selectOrganizacion,
} from "Redux/DynamicConfigCompany/dcc-select";
//Components
import FormInput from "Components/FormInput/FormInput";
import {
  Title,
  CustomButton,
  GoToSignin,
  ErrorLabel,
  Spinner,
} from "Components";

class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      failMessage: false,
      professions: [],
      interests: [],
      newUser: {
        name: "",
        firstLastName: "",
        secondLastName: "",
        dni: "",
        phone: "",
        email: "",
        professionalCode: "",
        professionId: [],
        organizationId: [],
        password: "",
        confirmPassword: "",
        myInterests: [],
        additionalFields: {
          age: "",
          shirtSize: "",
          sex: "",
          phone2: "",
        },
      },
      modalState: {
        setModalCopyCRVisible: false,
        setModalPolicyCRVisible: false,
      },
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      name,
      firstLastName,
      email,
      password,
      confirmPassword,
      additionalFields,
    } = this.state.newUser;

    // VALIDATORS
    if (
      !name ||
      !firstLastName ||
      !email ||
      !additionalFields.age ||
      !additionalFields.sex ||
      !additionalFields.shirtSize ||
      !additionalFields.phone2
    ) {
      //MixPanel unsuccessful signup
      MixPanel.track(MixPanel.TYPES.UNSUCCESSFUL_SIGNUP);
      this.setState({
        failMessage: "Debe llenar todos los campos requeridos [*]",
      });
      setTimeout(() => {
        this.setState({
          failMessage: false,
        });
      }, 5000);
      return;
    }

    //Password
    if (password !== confirmPassword) {
      //MixPanel unsuccessful signup
      MixPanel.track(MixPanel.TYPES.UNSUCCESSFUL_SIGNUP);

      this.setState({ failMessage: "Las contraseñas no coinciden" });
      setTimeout(() => {
        this.setState({
          failMessage: false,
        });
      }, 5000);
      return;
    }

    if (additionalFields.age < 13) {
      MixPanel.track(MixPanel.TYPES.UNSUCCESSFUL_SIGNUP);
      this.setState({
        failMessage:
          "Debe de tener más de 13 años para participar de este bautismo.",
      });
      setTimeout(() => {
        this.setState({
          failMessage: false,
        });
      }, 5000);
      return;
    }

    try {
      this.setState({
        loading: true,
      });
      const signupResult = await signUp({
        ...this.state.newUser,
        companyId: this.props.company.id,
        countryId: this.props.company.countryId,
      });
      //MixPanel successFul signup
      MixPanel.track(MixPanel.TYPES.SUCCESSFUL_SIGNUP);
      this.setState({
        loading: false,
      });
      if (signupResult?.status === 409) {
        this.setState({
          failMessage: signupResult.response,
        });

        setTimeout(() => {
          this.setState({
            failMessage: false,
          });
        }, 5000);
        return;
      }
      if (!signupResult) {
        this.setState({
          failMessage: signupResult.response,
        });
        setTimeout(() => {
          this.setState({
            failMessage: false,
          });
        }, 5000);
        return;
      }
      if (signupResult.organizations.length <= 1) {
        this.props.setOrganizationModule(signupResult.organizations[0]);
        await this.props.setOrganizationSeleted(signupResult.organizations[0]);
      }
      this.props.setCurrentUser(signupResult);
    } catch (error) {
      this.setState({
        loading: false,
        failMessage:
          "Hubo un problema al crear la cuenta. Por favor vuelva a intentarlo",
      });
      setTimeout(() => {
        this.setState({
          failMessage: false,
        });
      }, 5000);
    }
  };

  handleChange = (event) => {
    let { name, value } = event.target;

    if (name === "email") {
      value = value.toLowerCase();
    }

    this.setState({
      newUser: {
        ...this.state.newUser,
        [name]: value,
      },
    });
  };

  handleAdditionalFieldsChange = (event) => {
    let { name, value } = event.target;

    this.setState({
      newUser: {
        ...this.state.newUser,
        additionalFields: {
          ...this.state.newUser.additionalFields,
          [name]: value,
        },
      },
    });
  };

  handleChangeSelect = (name, value) => {
    if (name !== "interestId") {
      if (name === "organizationId") {
        this.setState({ professions: [] });
        this.setState({ interests: [] });
        getProfessions(window.location.host).then((professions = []) =>
          this.setState({ professions })
        );
        getInterests(window.location.host).then((interests = []) =>
          this.setState({ interests })
        );
      }
      this.setState({
        newUser: {
          ...this.state.newUser,
          [name]: value,
        },
      });
    } else {
      this.setState({
        newUser: {
          ...this.state.newUser,
          myInterests: value,
        },
      });
    }
  };

  handleHideModal = () => {
    this.setState({
      modalState: {
        setModalCopyCRVisible: false,
        setModalPolicyCRVisible: false,
      },
    });
  };

  render() {
    const { loading, failMessage } = this.state;
    const {
      name,
      dni,
      phone,
      firstLastName,
      secondLastName,
      email,
      password,
      confirmPassword,
      additionalFields,
    } = this.state.newUser;
    const { currentUser } = this.props;
    //all organizacion company
    return currentUser ? (
      <Redirect to={ROUTES.HOME} />
    ) : (
      <div className="lg:px-24 bg-cover bg-sign-up-background bg-fixed py-8">
        {loading && <Spinner />}
        <form
          style={{
            maxWidth: "100vw",
          }}
          className="my-8 lg:mx-auto pt-5 pb-8 px-4 lg:px-20 
          flex flex-col shadow-none bg-transparent lg:border-2 lg:border-white lg:border-solid 
          rounded-2xl h-auto items-center"
          action="POST"
          onSubmit={this.handleSubmit}
        >
          <Title $loginTittle>Hemos finalizado el periódo de inscripciones para el próximo Bautismo. Gracias por tu interés en participar.</Title>
          <GoToSignin />
        </form>
        {/*<form
          style={{
            maxWidth: "100vw",
          }}
          className="my-8 lg:mx-auto pt-5 pb-8 px-4 lg:px-20 
          flex flex-col shadow-none bg-transparent lg:border-2 lg:border-white lg:border-solid 
          rounded-2xl h-auto items-center"
          action="POST"
          onSubmit={this.handleSubmit}
        >
          <Title $loginTittle>Regístrate</Title>

          <FormInput
            name="name"
            type="text"
            maxLength={100}
            value={name}
            onChange={this.handleChange}
            label="Nombre completo*"
            required
          />

          <FormInput
            name="firstLastName"
            type="text"
            maxLength={100}
            value={firstLastName}
            onChange={this.handleChange}
            label="Primer apellido*"
            required
          />

          <FormInput
            name="secondLastName"
            type="text"
            maxLength={100}
            value={secondLastName}
            onChange={this.handleChange}
            label="Segundo apellido*"
            required
          />

          <FormInput
            name="dni"
            type="text"
            maxLength={100}
            value={dni}
            onChange={this.handleChange}
            label="Cédula*"
            required
          />

          <FormInput
            name="phone"
            type="number"
            value={phone}
            onChange={this.handleChange}
            label="Teléfono 1*"
            required
          />

          <FormInput
            name="phone2"
            type="number"
            value={additionalFields.phone2}
            onChange={this.handleAdditionalFieldsChange}
            label="Teléfono 2*"
            required
          />

          <FormInput
            name="age"
            type="number"
            maxLength={3}
            value={additionalFields.age}
            onChange={this.handleAdditionalFieldsChange}
            label="Edad*"
            min={0}
            required
          />
          <span className="text-white font-bold w-full text-left text-lg">
            Si usted es menor de 13 años debe de participar en el bautismos para
            niños.
          </span>
          <div className="mt-1 mb-2 w-full">
            {additionalFields.sex && (
              <label
                style={{
                  position: "relative",
                  top: "-8px",
                  left: "1%",
                  fontSize: "14px",
                  color: "#FFF",
                  fontWeight: "bold",
                  marginBottom: 0,
                }}
              >
                Sexo*
              </label>
            )}
            <select
              required
              style={{
                color: "#446e7e",
                fontSize: "16px",
              }}
              className="w-full bg-[#FFF] appearance-none px-3 py-2.5 rounded-none"
              value={additionalFields.sex}
              onChange={(evt) => {
                this.setState({
                  newUser: {
                    ...this.state.newUser,
                    additionalFields: {
                      ...this.state.newUser.additionalFields,
                      sex: evt.target.value,
                    },
                  },
                });
              }}
            >
              <option value={null}>Seleccione su sexo*</option>
              <option value="Hombre">Hombre</option>
              <option value="Mujer">Mujer</option>
            </select>
          </div>
          <div
            className={`${
              !additionalFields.shirtSize ? "mt-3" : "mt-1"
            } mb-3 w-full`}
          >
            {additionalFields.shirtSize && (
              <label
                style={{
                  position: "relative",
                  top: "-8px",
                  left: "1%",
                  fontSize: "14px",
                  color: "#FFF",
                  fontWeight: "bold",
                  marginBottom: 0,
                }}
              >
                Talla de acamiseta*
              </label>
            )}
            <select
              required
              style={{
                color: "#446e7e",
                fontSize: "16px",
              }}
              className="w-full bg-[#FFF] appearance-none px-3 py-2.5 rounded-none"
              value={additionalFields.shirtSize}
              onChange={(evt) => {
                this.setState({
                  newUser: {
                    ...this.state.newUser,
                    additionalFields: {
                      ...this.state.newUser.additionalFields,
                      shirtSize: evt.target.value,
                    },
                  },
                });
              }}
            >
              <option value="">Seleccione una talla de camiseta*</option>
              <option value="S">Talla S</option>
              <option value="M">Talla M</option>
              <option value="L">Talla L</option>
              <option value="XL">Talla XL</option>
              <option value="XXL">Talla XXL</option>
            </select>
          </div>
          <FormInput
            name="email"
            type="email"
            maxLength={150}
            value={email}
            onChange={this.handleChange}
            label="Correo electrónico*"
            required
          />

          <FormInput
            name="password"
            type="password"
            maxLength={50}
            minLength={8}
            value={password}
            onChange={this.handleChange}
            label="Contraseña*"
            required
          />

          <FormInput
            name="confirmPassword"
            type="password"
            maxLength={50}
            minLength={8}
            value={confirmPassword}
            onChange={this.handleChange}
            label="Confirmar Contraseña*"
            required
          />
          <CustomButton $singupbutton>Registrarme</CustomButton>
          <ErrorLabel
            style={{
              backgroundColor: "#c00",
              width: "100%",
            }}
            value={failMessage}
          />
          <GoToSignin />
        </form>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  company: selectCompany(state),
  organizations: selectOrganizacion(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setCompanyData: (data) => dispatch(setCompanyData(data)),
  setOrganizationModule: (organizationSelect) =>
    dispatch(setModuleOrganization(organizationSelect)),
  setOrganizationSeleted: (organization) =>
    dispatch(setOrganizationSeleted(organization)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  React.memo(SignUp)
);
